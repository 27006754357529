import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import knsblue from '../assets/images/logo/kns-blue.svg'
import fmicon from '../assets/images/icon/fm-icon.svg'
import website from '../assets/images/icon/website.svg'
import arrow from '../assets/images/icon/arrow-link.svg'
import cmsicon from '../assets/images/icon/cms-icon.svg'
import crmicon from '../assets/images/icon/crm-icon.svg'
import $ from 'jquery'
import LocalizedLink from '../LocalizedLink'

import styles from '../components/style.module.css'
import locales from '../constants/locales'

class PlatformsPage extends React.Component {
  constructor(props) {
    super(props)
    var location = this.props.location.pathname
    var array = location.split('/')
    var result = array[array.length - 1]
    if (result === 'content-management-and-application-platform') {
      result = 'platforms/content-management-and-application-platform'
    } else if (result === 'customer-data-and-marketing-platform') {
      result = 'platforms/customer-data-and-marketing-platform'
    } else if (result === 'fm') {
      result = 'platforms/fm'
    } else if (result === 'venuex') {
      result = 'platforms/venuex'
    }
    this.state = {
      tr: (
        <Link
          className={
            'en' === this.props.pageContext.locale ? styles.active : ''
          }
          key={'EN'}
          to={locales['en'].default ? `/${result}` : `/${result}`}
        >
          <span>{locales['en'].locale}</span>
        </Link>
      ),
      en: (
        <Link
          className={
            'tr' === this.props.pageContext.locale ? styles.active : ''
          }
          key={'TR'}
          to={
            locales['tr'].default ? '/' : `/${locales['tr'].path}/${location}`
          }
        >
          <span>{locales['tr'].locale}</span>
        </Link>
      ),
      hash: '',
      name: '',
      phone: '',
      from: '',
      message: '',
      agreement: false,
    }
  }

  componentDidMount() {
    if (window.location.pathname === '/platformsPage') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }

    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function() {
      $('.mobile-menu').fadeOut(200)
    })
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>

                <div className="page-prp">
                  <h1>
                    <FormattedMessage id="platforms" />
                  </h1>
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="solutions">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center add-flx">
                  <Link to="/platforms/content-management-and-application-platform" className="solution">
                    <div className="detail-link">
                      <img src={arrow} />
                      <span>LEARN MORE</span>
                    </div>
                    <img src={cmsicon} />
                    <h1>
                    Content Management<br/>&<br />Application Platform
                    </h1>
                    
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <p>
                          Centralized Management, Smooth Process, Easy to use UI
                        </p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <p>
                          Centralized Management, Smooth Process, Easy to use UI
                        </p>
                      </>
                    )}
                    <div className="kns-tags">
                      
                      <span>Digital Directory</span>
                      <span>Digital Signage</span>
                      <span>LED Display</span>
                      <span>Website</span>
                      <span>Mobile Application</span>
                    </div>
                  </Link>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center add-flx">
                  <Link to="/platforms/customer-data-and-marketing-platform" className="solution">
                    <div className="detail-link">
                      <img src={arrow} />
                      <span>LEARN MORE</span>
                    </div>
                    <img src={crmicon} />
                    <h1>
                    Customer Data<br/>&<br />Marketing Platform
                    </h1>
                    {/*<div className="tags"><span className="software">Software</span><span className="hardware">Hardware</span></div>*/}
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <p>
                        Collect and Unify Data From Each Online and Physical Customer Touchpoint 
                        </p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <p>
                        Collect and Unify Data From Each Online and Physical Customer Touchpoint 
                        </p>
                      </>
                    )}

<div className="kns-tags">
                      
                      <span>Email</span>
                      <span>SMS</span>
                      <span>Push Notification</span>
                    </div>

                  </Link>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center add-flx opc-box">
                  <a className="solution">
                    <img src={fmicon} />
                    <h1>Facility Management</h1>
                    {/* <div className="tags"><span className="software">Software</span><span className="hardware">Hardware</span></div>*/}
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <p>All-In-One Solution for Facility Management</p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <p>All-In-One Solution for Facility Management</p>
                      </>
                    )}

                    <span>Coming Soon</span>
                  </a>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center add-flx">
                  <Link to="/platforms/venuex" className="solution">
                    <div className="detail-link">
                      <img src={arrow} />
                      <span>LEARN MORE</span>
                    </div>
                    <img src={website} />
                    <h1>Venuex</h1>
                    {/*<div className="tags"><span className="software">Software</span></div>*/}
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <p>Future Ready Accessible Venues</p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <p>Future Ready Accessible Venues</p>
                      </>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </body>
      </Layout>
    )
  }
}
PlatformsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default PlatformsPage
